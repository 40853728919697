<template>
<NuxtLink
    class="internal-storefront"
    :class="['shopify']"
    :aria-label="`button to purchase ${pack?.title}`"
    tabindex="0"
    role="button" v-on:click="onBuyClick()"
    v-on:keydown.enter="onBuyClick()"
>
    <div class="buy-for-text">
        {{ $t('GAME_DETAIL.BUY_FOR') }}
    </div>
    <span v-if="pack" class="icons">

        <img class="icon windows" src="../../assets/icons/windows.png" alt="windows" focusable="false">
        <img
            v-if="pack.slug !== 'ydkj-classic'" class="icon apple" src="../../assets/icons/apple.svg"
            alt="apple"
            focusable="false"
        >
        <img
            v-if="pack.slug !== 'ydkj-classic'" class="icon linux" src="../../assets/icons/linux.png"
            alt="linux"
            focusable="false"
        >

    </span>
</NuxtLink>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import Shopify from '@jackboxgames/shopify'
import { ShoppingCart } from '$services/shopify'
import type { JBGWeb } from '$types/JBGWeb'

export default defineComponent({
    props: {
        product: Object as PropType<JBGWeb.ProductDetail | null>,
        shopifyVariant: Object as PropType<Shopify.Shopify.Variant | null>,
        pack: Object as PropType<JBGWeb.PackDetail | null>
    },
    methods: {
        async onBuyClick() {
            let variantID
            if (this.product) {
                // TODO: safer way of getting variant ID?
                variantID = this.product.shopifyData.data.product.variants.edges[0].node.id
            }
            if (this.shopifyVariant) {
                variantID = this.shopifyVariant?.id
            }

            if (!variantID) {
                (console.error('couldn\'t get variant id'))
                return
            }

            // create a line item
            const lineItem: Shopify.Shopify.LineItem = {
                merchandiseId: variantID,
                quantity: 1,
                attributes: []
            }

            try {
                await this.$shopify.addItemToCart(lineItem)
                ShoppingCart.open()
                // TODO: analytics
            } catch (error) {
                console.error('Failed to add item to cart:', error.message)
            }
        }
    }
})

</script>

<style lang="scss" scoped>
@use "$styles/kit.scss" as *;

.internal-storefront {
    background: linear-gradient(90deg, #9CA7FE 0%, #C0C7FF 100%);
    border: none;
    border-radius: 4px;
    color: var(--neutral-900);
    cursor: pointer;
    line-height: 18px;
    margin: 8px 0px 12px 0px;
    padding: 16px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @include mq-xsmall {
        padding: 12px;
        margin: 8px 0px 8px 0px;
    }

    &:hover:not(:disabled) {
        color: var(--active-tab);
        background: linear-gradient(90deg, #9CA7FE 0%, #C0C7FF 100%);
        box-shadow: 0px 0px 27.901px 0px #7b88ee, 0px 0px 1.329px 0px #7b88ee, 0px 0px 0.664px 0px #7b88ee;
    }

    &:active {
        background: linear-gradient(180deg, #8085a6 0%, #8F9CFF 100%);
    }

    .buy-for-text {
        font-size: 16px;
        font-style: normal;
        font-weight: 800;
        line-height: 16px;
        margin-left: 4px;
        text-align: left;
        text-transform: uppercase;
        @include mq-xsmall {
            font-size: 14px;
            line-height: 14px;
            font-weight: 800;
        }
    }

    .icons {
        display: flex;
        gap: 8px;

        .windows {
            height: 16px;
            width: 17px;
        }
        .apple {
            height: 16px;
            width: 14px;
            filter: invert(90%);
        }
        .linux {
            height: 18px;
            width: 15px;
        }
    }
}
</style>
